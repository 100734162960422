body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

#root {
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

code {
  font-family: 'Montserrat', sans-serif;
}

span {
  font-weight: 500;
}

.icon-heart:before {
  content: "\2665";
  color: #fbc91b;
  width: .75rem!important;
}

a {
  text-decoration: none;
}

/* Video */
video {
  object-fit: fill;
}

.ui-video-player-component {
    min-height: 100% !important;
}

.ui-video-player-component .ui-video-player-controls .controllers-block .hq {
  display: none !important;
}