.navigate-top-enter {
  opacity: 0;
}

.navigate-top-enter-active {
  opacity: 1;
  transition: opacity .1s;
}

.navigate-top-exit {
  opacity: 1;
}

.navigate-top-exit-active {
  opacity: 0;
  transition: opacity .1s;
}

.sponsor-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-support-container,
.carousel-partner-support-container {
  margin-bottom: 40px;
}