.wrapper h1 {
    color: #fbc91b;
    text-align: center;
}

.wrapper table {
    border-collapse: collapse;
    border-spacing: 0;
}

.wrapper td, th {
    padding: 0;
}

.wrapper td {
    padding-top: 10px;
    padding-bottom: 15px;
}

.wrapper td:first-of-type {
    padding-left: 36px;
    width: 66px;
    text-align: center;
}

.c-table {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1.25;
    margin-bottom: 24px;
    width: 100%;
}

.c-table__cell {
    padding: 12px 0 12px 0;
    word-wrap: break-word;
    text-align: center;
}

.c-table__header tr {
    color: #fff;
}

.c-table__header th {
    background-color: #fbc91b;
    padding: 18px 6px 18px 12px;
    color: black;
}

.c-table__header th:first-child {
    border-top-left-radius:  4px;
}

.c-table__header th:last-child {
    border-top-right-radius: 4px;
}

.c-table__body tr {
    border-bottom: 1px solid rgba(113, 110, 182, 0.15);
}

.c-table__body tr:last-child {
    border-bottom: none;
}

.c-table__body tr:hover {
    background-color: rgba(113, 110, 182, 0.15);
    color: #272b37;
}

.c-table__label {
    display: none;
}

/* Mobile table styles */

@media only screen and (max-width: 767px) {

    table, thead, tbody, th, td, tr {
        display: block;
    }

    td:first-child {
        padding-top: 24px;
    }

    td:last-child {
        padding-bottom: 24px;
    }

    .c-table {
        border: 1px solid rgba(113, 110, 182, 0.15);
        font-size: 15px;
        line-break: 1.2;
    }

    .c-table__header tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .c-table__cell {
        padding: 12px 24px;
        position: relative;
        width: 100%;
        word-wrap: break-word;
    }

    .c-table__label {
        color: #272b37;
        display: block;
        font-size: 10px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 6px;
        text-transform: uppercase;
    }

    .c-table__body tr:hover {
        background-color: transparent;
    }

    .c-table__body tr:nth-child(odd) {
        background-color: rgba(113, 110, 182, 0.04);
    }
}

.resource {
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    border: 1px solid transparent;
    font-size: 12px;
    margin-right: 20px;
}

.capital {
    background: #f7e4b1
}

.training {
    background: #93e088;
}

.website {
    background: #72ddc3;
}

.automation {
    background: #9cc6fa;
}

.payment {
    background: #bedeeb;
}

.marketing {
    background: #f19ce1;
}

.design {
    background: #f4a880;
}